import MiniIconGreen from "../../elements/brand/MiniIconGreen";
import Image from "next/image";
import { Person, CircleCheckFill } from "akar-icons";
import getUserPersona from "../../../libs/getUserPersona";
import { useRouter } from "next/router";
import getTranslatedText from "../../../libs/getTranslatedText";

/* Author:{
  title: "",
  avatar:"image_id",
  first_name: "",
  last_name: "",
  country: "",
  city: "",
} */

const MiniAuthorBox = ({ author, type = "regular" }) => {
  const { locale } = useRouter();

  if (type === "regular") {
    return (
      <div className="flex items-center">
        <div className="flex items-center justify-center p-2 bg-white rounded-full shadow w-11 h-11">
          <MiniIconGreen />
        </div>
        <div className="ml-3">
          <div className="text-sm font-medium">hortiturkey</div>
          <div className="text-sm text-neutral-600">
            {getTranslatedText(
              locale,
              "Baş Editör",
              "utilities.authorBox.editorInChief"
            )}
          </div>
        </div>
      </div>
    );
  }
  if (type === "company" || type === "event") {
    return (
      <div className="flex items-center">
        <div className="relative shadow w-11 bg-white h-11 p-2 overflow-hidden rounded-full">
          <Image
            src={process.env.NEXT_PUBLIC_IMAGE_URL + author.avatar}
            alt={author.title + " logo"}
            title={author.title}
            layout="responsive"
            objectFit="contain"
            objectPosition="center"
            width={60}
            height={60}
          />
        </div>

        <div className="ml-3">
          <div className="flex items-center gap-1">
            <div className="text-sm font-medium">{author.title}</div>
            <span className="text-blue-500">
              <CircleCheckFill size={14} />
            </span>
          </div>

          <div className="text-sm text-neutral-600">
            {author.country + ", " + author.city}
          </div>
        </div>
      </div>
    );
  }
  if (type === "ugc") {
    return (
      <div className="flex items-center">
        <div
          className={`${
            !author.avatar ? "text-secondaryDark" : null
          } sm:w-11 sm:h-11 w-9 h-9 rounded-full p-1 overflow-hidden bg-white shadow flex justify-center items-center`}
        >
          {author.avatar ? (
            <div className="block w-full overflow-hidden rounded-full">
              <Image
                src={
                  process.env.NEXT_PUBLIC_IMAGE_URL +
                  author.avatar +
                  "?key=avatar"
                }
                alt={author.avatar.title || "profil resmi"}
                title={author.avatar.title || "profil resmi"}
                layout="responsive"
                width={40}
                height={40}
              />
            </div>
          ) : (
            <Person size={20} />
          )}
        </div>
        <div className="ml-3">
          <div className="text-sm font-medium">
            {author.first_name + " " + author.last_name}
          </div>
          <div className="text-sm text-neutral-600">
            {author.title
              ? author.title
              : author.persona
              ? getUserPersona(locale, author.persona)
              : getTranslatedText(locale, "Üye", "utilities.authorBox.member")}
            {author.city ? ", " + author.city : ""}
          </div>
        </div>
      </div>
    );
  }
};

export default MiniAuthorBox;
