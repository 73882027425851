import getTranslatedText from "./getTranslatedText";
const getUserPersona = (locale, persona) => {
  const userPersonas = {
    professional: getTranslatedText(
      locale,
      "Sektör Profesyoneli",
      "utilities.personas.professional"
    ),
    farmer: getTranslatedText(locale, "Çiftçi", "utilities.personas.farmer"),
    engineer: getTranslatedText(
      locale,
      "Ziraat Mühendisi",
      "utilities.personas.engineer"
    ),
    consultant: getTranslatedText(
      locale,
      "Tarım Danışmanı",
      "utilities.personas.consultant"
    ),
    student: getTranslatedText(locale, "Öğrenci", "utilities.personas.student"),
    hobbyist: getTranslatedText(
      locale,
      "Hobici / Bahçeci",
      "utilities.personas.hobbyist"
    ),
  };
  return userPersonas[persona];
};

export default getUserPersona;
