import ListingTagsList from "./ListingTagsList";
import Image from "next/dist/client/image";
import Link from "next/dist/client/link";
import BasicBottomReactions from "./BasicBottomReactions";

const farmingTypes = {
  open_field: "Açık Arazi",
  greenhouse: "Örtüaltı",
  vertical: "Topraksız Tarım",
};

const GuideMainListing = ({
  title,
  farming_type,
  date,
  image,
  description,
  likeCount,
  commentCount,
  link,
  locale,
}) => {
  return (
    <Link href={"/bitki-yetistiriciligi/" + link} locale="tr">
      <a>
        <div className="px-6 py-6 transition-colors duration-100 ease-out border border-gray-200 rounded-md hover:border-primaryLight">
          {/* Title */}
          <h3 className="text-xl font-medium">{title}</h3>
          {/* Tags */}
          <div className="mt-3">
            <ListingTagsList
              isNew={
                date && new Date(date) > new Date() - 86400000 /* 24 Hours */
              }
              category={farmingTypes[farming_type]}
              date={date}
              type="REHBER"
              locale={locale}
            />
          </div>
          {/* Post Image */}
          <div className="max-w-full mt-4 w-full rounded-md h-[200px] sm:h-[300px] relative overflow-hidden object-cover object-center">
            <Image
              src={process.env.NEXT_PUBLIC_IMAGE_URL + image}
              alt={title}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              placeholder="blur"
              blurDataURL={
                process.env.NEXT_PUBLIC_IMAGE_URL +
                image +
                "?key=blurry-thumbnail"
              }
            />
          </div>
          {/* Description */}
          <p className="mt-4 text-neutral-600">{description}</p>
          <BasicBottomReactions
            likeCount={likeCount}
            commentCount={commentCount}
            locale={locale}
          />
        </div>
      </a>
    </Link>
  );
};

export default GuideMainListing;
