import MainPostLoader from "./MainPostLoader";
import MainGuideLoader from "./MainGuideLoader";
import ControlMiniLoader from "./ControlMiniLoader";

const ContentListLoader = () => {
  return (
    <div className="flex flex-col gap-8">
      <MainPostLoader />
      <MainGuideLoader />
      <div className="grid grid-cols-2 gap-8">
        <ControlMiniLoader />
        <ControlMiniLoader />
        <ControlMiniLoader />
        <ControlMiniLoader />
      </div>
    </div>
  );
};

export default ContentListLoader;
